import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Badge from "@mui/material/Badge";

export const Routes = [
  {
    name: "Home",
    path: "/",
    component: "Home",
    icon: () => <HomeRoundedIcon />,
  },
  {
    name: "Shop",
    path: "/shop",
    component: "Shop",
    icon: () => <ListAltIcon />,
  },
  {
    name: "About",
    path: "/about",
    component: "About",
    icon: () => <HomeRoundedIcon />,
  },
  {
    name: "Blog",
    path: "/blog",
    component: "Blog",
    icon: () => <MenuBookIcon />,
  },
  {
    name: "Contact",
    path: "/contact",
    component: "Contact Us",
    icon: () => <PermPhoneMsgIcon />,
  },
  {
    name: "Cart",
    path: "/cart",
    icon: (products) => (
      <Badge
        badgeContent={products && products.length}
        color="secondary"
        variant="dot"
      >
        <ShoppingCartIcon />
      </Badge>
    ),
    component: (products) => (
      <div>
        <Badge
          badgeContent={products && products.length}
          color="secondary"
          variant="dot"
        >
          <ShoppingCartIcon />
        </Badge>
        Cart
      </div>
    ),
  },
];
