const ApiSuffix = {
  // logout: "/api/logout/",
  getCityList: "/api/get-city-list/",
  register: "/api/register-user/",
  generateOTP: "/api/send-otp/?username=",
  verifyOtp: "/api/verify-otp/?username=",
  getUserProfile: "/api/my-profile/",
  getMenuPageData: "/api/get-menu-page-data/menu/",
  getCityMenu: "/api/get-menu/",
  getStoreCatalogue: "/api/get-store-categories/",
  getCategoryData: "/api/get-products/",
  // getCategoryData: "/api/get-store-menu/",
  getProductDetails: "/api/get-product-info/?displayURL=",
  getAddons: "/api/get-add-ons/?id=",
  validateCart: "/api/validate-cart/",
  getAddressInfo: "/api/get-saved-address/?cityID=",
  addUserAddress: "/api/create-new-address/",
  calculateShipping: "/api/get-shipping-charges/",
  applyCoupon: "/api/apply-coupon/",
  orderRegistration: "/api/register-order/",
  deleteUserAddress: "/api/delete-address/?id=",
  getOrderDetail: "/api/order-details/",
  getHomePageData: "/api/home-page-data/",
  blogGeneric: "/api/blog/",
  getBlogData: "/api/get-blog-data/",
  getContactData: "/api/get-contact-data/",
  registerReferral: "/api/referral-register/",
  getStoreBills: "/api/get-pos-bills/",
  getBillDetails: "/api/get-bill-details/",
  getAboutUs: "/api/get-html-data/?pageName=about-us",
  getCategories: "/api/get-categories/",
  getCategoryFilters: "/api/get-category-filters/?catID=",
  getProducts: "/api/get-products/?catID=",
  getProductInfo: "/api/get-product-info/?sku=",

  sendOtp: "/api/send-otp/?number=",
  validateOtp: "/api/validate-otp/?number=",
  getAddresses: "/api/get-saved-address/",
  addNewAddress: "/api/add-address/",
  validatePincode: "/api/pincode-details/?pincode=",
  addressValidation: "/api/address-validation/",
  checkPromoCode: "/api/apply-discount-coupon/",
  createOrder: "/api/create-order/",
  getProfileInfo: "/api/get-user-profile/",
  editAddress: "/api/edit-address/",
  deleteAddress: "/api/delete-address/?id=",
  getOrderDetails: "/api/get-order-details/?orderID=",
  postQuery: "/api/fill-query-form/",
  getPolicies: "/api/home-page-api/",
  getPolicyData: "/api/get-policy-data/?name=",
  getAllBlogs: "/api/get-blogs/",
  getBlogDetail: "/api/get-blogs/?url=",

  /**Unused */
  searchProduct: "/api/search-products/?search=",
  getBannerData: "/home-page-data/",
  getCategoryDetails: "/get-products/",
  customOrderRegistration: "/api/custom-order-registration/",
  getCatalogueData: "/get-catalogue/",

  // getCityStores: "/get-stores-locations/",

  homeDate: "/api/home-page-api/",
};

export default ApiSuffix;
