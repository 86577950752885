import { cloneElement, useEffect, useState } from "react";

import { IMAGES } from "../Images";
import { Routes } from "../Routes";
import { hydrate } from "../Store/actions";

import "./Header.css";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AuthDialog from "../Auth/AuthDialog";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Header = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [activeValue, setActiveValue] = useState("/");
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const products = useSelector((state) => state.products);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setAnchorEl(null);
    navigate("/");
  };
  const handleOpenProfileClick = (event) => {
    if (isAuthenticated) {
      setAnchorEl(event.currentTarget);
    } else {
      handleOpenAuthDialog();
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenAuthDialog = () => {
    setOpenAuthDialog(true);
  };

  const handleCloseAuthDialog = () => {
    setOpenAuthDialog(false);
  };

  useEffect(() => {
    if (
      location.pathname.split("/")[1] === "product" ||
      location.pathname.split("/")[1] === "category"
    ) {
      setActiveValue("/catalogue");
    } else {
      setActiveValue(location.pathname);
    }
    if (localStorage.getItem("cart") !== null) {
      const cartLocalStorage = JSON.parse(window.localStorage.getItem("cart"));
      dispatch(
        hydrate({
          products: cartLocalStorage.products,
          totalWeight: cartLocalStorage.totalWeight,
          cartTotal: cartLocalStorage.cartTotal,
        })
      );
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsAuthenticated(true);
      let user = localStorage.getItem("user");
      setUser(JSON.parse(user));
    } else {
      setIsAuthenticated(false);
      if (location.pathname === "/checkout" || location.pathname === "/order") {
        setOpenAuthDialog(true);
      }
    }
  }, [openAuthDialog, location]);

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar position="fixed" style={{ backgroundColor: "white" }}>
          <Toolbar>
            <div className="header-container">
              <div className="header-title">
                <div onClick={() => navigate("/")} style={{ color: "#222" }}>
                  <img
                    src={IMAGES.HEADER_LOGO}
                    alt="TechBRJ"
                    style={{ width: "180px", padding: "5px" }}
                  />
                </div>
              </div>
              <div className="header-navigation">
                {Routes.map((route, i) => {
                  if (route.name === "Cart") {
                    return (
                      <div
                        key={i}
                        onClick={() => navigate(route.path)}
                        className={
                          activeValue === route.path
                            ? "header-navigation-item active"
                            : "header-navigation-item"
                        }
                      >
                        {route.component(products)}
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={i}
                        onClick={() => navigate(route.path)}
                        className={
                          activeValue === route.path
                            ? "header-navigation-item active"
                            : "header-navigation-item"
                        }
                      >
                        {route.component}
                      </div>
                    );
                  }
                })}

                <div
                  className="header-navigation-item"
                  aria-controls="login-menu"
                  aria-haspopup="true"
                  onClick={(e) => handleOpenProfileClick(e)}
                >
                  {isAuthenticated ? (
                    <Avatar className="header-avatar">
                      {user.name.charAt(0)}
                    </Avatar>
                  ) : (
                    <>
                      <AccountCircleIcon /> Login
                    </>
                  )}
                </div>

                <Menu
                  id="login-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                      handleCloseMenu();
                    }}
                  >
                    <b>Profile</b>
                  </MenuItem>
                  <MenuItem onClick={() => handleLogOut()}>
                    <b>Logout</b>
                  </MenuItem>
                </Menu>
              </div>
              <div className="mobile-login">
                <IconButton
                  size="small"
                  onClick={(e) => handleOpenProfileClick(e)}
                >
                  {isAuthenticated ? (
                    <Avatar className="header-avatar">
                      {user.name.charAt(0)}
                    </Avatar>
                  ) : (
                    <>
                      <AccountCircleIcon />
                    </>
                  )}
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* <div className="bottom-navigation">
        <BottomNavigation
          value={activeValue}
          // onChange={handleChange}
          style={{ background: "#ffffff" }}
          showLabels
        >
          {Routes.map((route, i) => {
            if (route.path === "/cart") {
              return (
                <BottomNavigationAction
                  className={activeValue === route.path ? "active" : ""}
                  key={i}
                  value="/cart"
                  onClick={() => navigate("/cart")}
                  icon={route.icon(products)}
                  label="Cart"
                />
              );
            } else {
              return (
                <BottomNavigationAction
                  className={activeValue === route.path ? "active" : ""}
                  key={i}
                  value={route.path}
                  onClick={() => navigate(route.path)}
                  icon={route.icon()}
                  label={route.name}
                />
              );
            }
          })}
        </BottomNavigation>
      </div> */}
      <div className="bottom-navigation">
        <BottomNavigation
          value={activeValue}
          style={{ background: "#ffffff" }}
          showLabels
        >
          {Routes.map((route, i) => {
            if (route.path === "/cart") {
              return (
                <BottomNavigationAction
                  className={activeValue === route.path ? "active" : ""}
                  key={i}
                  value="/cart"
                  onClick={() => navigate("/cart")}
                  icon={route.icon(products)}
                  label="Cart"
                />
              );
            } else if (route.name === "Blog") {
              // Do not render the Blog item
              return null;
            } else {
              return (
                <BottomNavigationAction
                  className={activeValue === route.path ? "active" : ""}
                  key={i}
                  value={route.path}
                  onClick={() => navigate(route.path)}
                  icon={route.icon()}
                  label={route.name}
                />
              );
            }
          })}
        </BottomNavigation>
      </div>

      <AuthDialog open={openAuthDialog} handleClose={handleCloseAuthDialog} />
    </>
  );
};

export default Header;
