import { forwardRef, useState } from "react";

import "./AuthDialog.css";

import ApiSuffix from "../ApiSuffix";
import BaseUrl from "../BaseUrl";
import { IMAGES } from "../Images";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AuthDialog = ({ open, handleClose, mode }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [genderError, setGenderError] = useState(false);
  const [genderErrorMessage, setGenderErrorMessage] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [sendingOtp, setSendingOtp] = useState(false);
  const [otpSended, setOtpSended] = useState(false);
  const [authType, setAuthType] = useState("login");
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [registeringUser, setRegisteringUser] = useState(false);
  const [apiError, setApiError] = useState(false);

  const navigate = useNavigate();

  const validateMail = () => {
    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!mailRegex.test(email)) {
      setEmailError(true);
      setEmailErrorMessage("Please enter a valid email address");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError(true);
      setPhoneNumberErrorMessage("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  const validateName = () => {
    if (!name.trim()) {
      setNameError(true);
      setNameErrorMessage("Please enter your name");
      return false;
    }
    return true;
  };

  const handleSendOtp = () => {
    setApiError("");
    const isPhoneNumberValid = validatePhoneNumber();
    if (isPhoneNumberValid) {
      setSendingOtp(true);
      let url = BaseUrl.dev + ApiSuffix.sendOtp + phoneNumber;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.error === false) {
            setSendingOtp(false);
            setOtpSended(true);
            setOtp(data.otp);
          } else {
            setSendingOtp(false);
            setApiError(data.error_msg);
          }
        })
        .catch((error) => {
          setSendingOtp(false);
          console.error("Error:", error);
        });
    } else {
      return;
    }
  };

  const handleValidateOtp = () => {
    setApiError("");

    if (!otp.trim()) {
      setOtpError(true);
      setOtpErrorMessage("Please enter your otp");
    } else {
      let url =
        BaseUrl.dev + ApiSuffix.validateOtp + phoneNumber + "&otp=" + otp;
      setValidatingOtp(true);
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.error === false) {
            setValidatingOtp(false);
            if (data.type === "login") {
              localStorage.setItem("token", data.token);
              localStorage.setItem(
                "user",
                JSON.stringify({ name: data.name, number: data.number })
              );
              setPhoneNumber("");
              setOtp("");
              setOtpSended(false);
              setName("");
              setEmail("");
              setAuthType("login");
              if (mode === "cart") {
                navigate("/checkout");
              }
              handleClose();
            } else if (data.type === "signup") {
              setAuthType("signup");
            }
          } else {
            setValidatingOtp(false);
            setApiError(data.error_msg);
            setName("");
            setEmail("");
          }
        })
        .catch((error) => {
          validatingOtp(false);
          console.error("Error:", error);
        });
    }
  };

  const handleRegisterUser = () => {
    setApiError("");
    const isNameValid = validateName();
    const isMailValid = validateMail();
    if (isMailValid && isNameValid) {
      let url = BaseUrl.dev + ApiSuffix.register;
      setRegisteringUser(true);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          number: phoneNumber,
          name: name,
          email: email,
          gender: gender,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.error === false) {
            setRegisteringUser(false);

            localStorage.setItem("token", data.token);
            localStorage.setItem(
              "user",
              JSON.stringify({ name: data.name, number: data.number })
            );
            setPhoneNumber("");
            setOtp("");
            setName("");
            setAuthType("login");
            setOtpSended(false);
            setEmail("");
            if (mode === "cart") {
              navigate("/checkout");
            }
            handleClose();
          } else {
            setRegisteringUser(false);
            setApiError(data.error_msg);
          }
        })
        .catch((error) => {
          setRegisteringUser(false);
          console.error("Error:", error);
        });
    } else {
      return;
    }
  };

  const starImg =
    "https://pdp.gokwik.co/kwikpass/assets/icons/carousel_icon.svg";

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-describedby="login-dialog"
      >
        <div className="auth-container">
          <div className="auth-login-image">
            <div className="container-content">
              <h4>
                Shop the best of Fabrics & Fits for you & your loved ones from
                Sartoren.
              </h4>
            </div>
            <div className="container-flex-content">
              <div className="content-box">
                <img className="star-img" src={starImg} alt="star" />
                <p className="content-title">Great Offers</p>
                <p className="content-text">Save upto 25% with us.</p>
              </div>

              <div className="content-box">
                <img className="star-img" src={starImg} alt="star" />
                <p className="content-title">Premium Fabric</p>
                <p className="content-text">
                  Premium Fabric that your body will love.
                </p>
              </div>

              <div className="content-box">
                <img className="star-img" src={starImg} alt="star" />
                <p className="content-title">Variety Sizes</p>
                <p className="content-text">
                  Sizes ranging from XS to 4XL for your perfect fit.
                </p>
              </div>
            </div>

            <div className="container-carousel-content">
              <Carousel
                showThumbs={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                infiniteLoop
                autoPlay
              >
                <div className="content-box mini">
                  <img className="star-img" src={starImg} alt="star" />
                  <p className="content-title">Great Offers</p>
                </div>
                <div className="content-box mini">
                  <img className="star-img" src={starImg} alt="star" />
                  <p className="content-title">Premium Fabric</p>
                </div>
                <div className="content-box mini">
                  <img className="star-img" src={starImg} alt="star" />
                  <p className="content-title">Variety Sizes</p>
                </div>
              </Carousel>
            </div>
          </div>
          <div className="auth-content-wrapper">
            <div>
              <img
                src={IMAGES.HEADER_LOGO}
                alt="techbrj"
                style={{ maxWidth: "250px" }}
              />
            </div>
            {/* <div className="auth-dialog-title">Welcome</div> */}
            <div className="auth-dialog-title">
              {authType === "signup"
                ? "Register"
                : otpSended
                ? "Validate OTP"
                : "Verify Number to Continue"}
            </div>
            <div className="auth-dialog-title">
              {authType === "signup" ? (
                <div className="register-input-wrapper">
                  <TextField
                    name="name"
                    label="Name"
                    value={name}
                    fullWidth={true}
                    error={nameError}
                    helperText={nameErrorMessage}
                    variant="outlined"
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(false);
                      setNameErrorMessage("");
                    }}
                  />
                  <TextField
                    name="email"
                    label="Email"
                    value={email}
                    fullWidth={true}
                    error={emailError}
                    helperText={emailErrorMessage}
                    variant="outlined"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                      setEmailErrorMessage("");
                    }}
                  />

                  <FormControl fullWidth error={genderError}>
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      labelId="gender-select-label"
                      id="gender-select"
                      value={gender}
                      label="Gender"
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderError(false);
                        setGenderErrorMessage("");
                      }}
                    >
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={2}>Female</MenuItem>
                      <MenuItem value={3}>Others</MenuItem>
                    </Select>
                    <FormHelperText>{genderErrorMessage}</FormHelperText>
                  </FormControl>
                </div>
              ) : (
                <div className="register-input-wrapper">
                  <TextField
                    name="phoneNumber"
                    type="number"
                    label="Phone Number"
                    variant="outlined"
                    error={phoneNumberError}
                    helperText={phoneNumberErrorMessage}
                    value={phoneNumber}
                    fullWidth={true}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setPhoneNumberError(false);
                      setPhoneNumberErrorMessage("");
                    }}
                  />
                  {otpSended ? (
                    <TextField
                      name="otp"
                      type="number"
                      label="Input OTP"
                      fullWidth={true}
                      error={otpError}
                      helperText={otpErrorMessage}
                      variant="outlined"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        setOtpError(false);
                        setOtpErrorMessage("");
                      }}
                    />
                  ) : null}
                </div>
              )}
            </div>
            {apiError && <div className="api-error">{apiError}</div>}
            <div style={{ marginBottom: "10px" }}>
              {authType === "signup" ? (
                <Button
                  color="primary"
                  variant="contained"
                  disabled={registeringUser}
                  onClick={() => handleRegisterUser()}
                >
                  {registeringUser ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Register"
                  )}
                </Button>
              ) : otpSended ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleValidateOtp()}
                  disabled={validatingOtp}
                >
                  {validatingOtp ? (
                    <CircularProgress size={25} />
                  ) : (
                    "Validate OTP"
                  )}
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleSendOtp()}
                  disabled={sendingOtp}
                >
                  {sendingOtp ? <CircularProgress size={25} /> : "Send OTP"}
                </Button>
              )}
            </div>

            <div className="auth-button-wrapper">
              {otpSended && authType === "login" ? (
                <Button
                  onClick={() => {
                    setOtp("");
                    setOtpError(false);
                    setPhoneNumber("");
                    setOtpSended(false);
                  }}
                  color="primary"
                  variant="outlined"
                >
                  Reset
                </Button>
              ) : null}
              {otpSended && authType === "login" ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => handleSendOtp()}
                  disabled={sendingOtp}
                >
                  {sendingOtp ? <CircularProgress size={25} /> : "Resend OTP"}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AuthDialog;
