export const add = (payload) => ({
  type: "ADD",
  payload: payload,
});

export const hydrate = (payload) => ({
  type: "HYDRATE",
  payload: payload,
});

export const emptyCart = () => ({
  type: "EMPTY",
});
