import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { lazy, Suspense } from "react";

import Header from "./Utils/Header/Header";
import Loader from "./Utils/Loader/Loader";

// import Product from "./Product/Product";
// import OrderDetail from "./OrderDetail/OrderDetail";
// import OrderFailure from "./OrderStatus/OrderFailure";
// import OrderSuccess from "./OrderStatus/OrderSuccess";

const Home = lazy(() => import(/* webpackChunkName: "Home" */ "./Home/Home"));

const About = lazy(() =>
  import(/* webpackChunkName: "About" */ "./About/About")
);

const Policy = lazy(() =>
  import(/* webpackChunkName: "Policy" */ "./Policy/Policy")
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ "./Profile/Profile")
);

const Catalogue = lazy(() =>
  import(/* webpackChunkName: "Catalogue" */ "./Shop/Shop")
);

const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ "./Blog/Blog"));

const BlogDetails = lazy(() =>
  import(/* webpackChunkName: "BlogDetails" */ "./Blog/BlogDetails")
);

const Contact = lazy(() =>
  import(/* webpackChunkName: "Contact" */ "./Contact/Contact")
);

const Category = lazy(() =>
  import(/* webpackChunkName: "Category" */ "./Category/Category")
);

const Product = lazy(() =>
  import(/* webpackChunkName: "Product" */ "./Product/Product")
);

const Cart = lazy(() => import(/* webpackChunkName: "Cart" */ "./Cart/Cart"));

const Checkout = lazy(() =>
  import(/* webpackChunkName: "Checkout" */ "./Checkout/Checkout")
);

const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "OrderDetail" */ "./OrderDetail/OrderDetail")
);

const OrderSuccess = lazy(() =>
  import(/* webpackChunkName: "OrderSuccess" */ "./OrderStatus/OrderSuccess")
);

const OrderFailure = lazy(() =>
  import(/* webpackChunkName: "OrderFailure" */ "./OrderStatus/OrderFailure")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#212121",
    },
    secondary: {
      main: "#fd4a87",
    },
  },
});

const SuspenseLayout = () => (
  <>
    <Header />

    <Suspense
      fallback={
        <div className="loader-container">
          <Loader />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  </>
);
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route element={<SuspenseLayout />}>
            <Route exact path="/" element={<Home />} />

            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            <Route path="/shop" element={<Catalogue />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:url" element={<BlogDetails />} />
            <Route path="/policy/:name" element={<Policy />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/category/:id" element={<Category />} />
            <Route path="/product/:sku" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/order/:id" element={<OrderDetail />} />
            <Route path="/order-success" element={<OrderSuccess />} />
            <Route path="/order-failure" element={<OrderFailure />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
